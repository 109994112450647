import React, {useEffect, useState} from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '../../src/@elegantstack/solid-ui-blocks/src/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '../../src/@elegantstack/solid-ui-blocks/src/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import BuildBrand from '../../src/@elegantstack/solid-ui-blocks/src/FeaturesWithPhoto/Block04'
import FeatureOne from '../../src/@elegantstack/solid-ui-blocks/src/FeaturesWithPhoto/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block04'
// import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
// import Stats from '../../src/@elegantstack/solid-ui-blocks/src/Stats/Block01'
// import Strategies from '@solid-ui-blocks/Features/Block06'
// import Download from '@solid-ui-blocks/CallToAction/Block02'
// import Testimonials from '@solid-ui-blocks/Testimonials/Block02'
import GetStarted from '@solid-ui-blocks/Stats/Block01'
// import Blog from '@solid-ui-blocks/Blog/Block01'
import Footer from '../../src/@elegantstack/solid-ui-blocks/src/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  // const [covidData, setCovidData] = useState(0)
  // const [vaccineData, setvaccineData] = useState(0)
  // const [date, setDate] = useState(0)
  // useEffect(() => {
  //   // get data from Corona data api
  //   fetch(`https://api.corona-zahlen.org/districts/05758`)
  //     .then(response => response.json()) // parse JSON from request
  //     .then(resultData => {
  //       setCovidData(resultData.data['05758'])
  //       setDate(resultData.meta.lastUpdate)
  //     })
  //     .catch(err => console.log(err))

  //   fetch(`https://api.corona-zahlen.org/vaccinations`)
  //     .then(response => response.json())
  //     .then(resultData => {
  //       setvaccineData(resultData.data)
  //     })
  //     .catch(err => console.log(err))
  // }, [])

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space='5' />
      <Container variant='full' sx={styles.buildBrandContainer}>
        <BuildBrand content={content['who-we-are']} />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <FeatureOne content={content['feature-one']} />
      <Divider space='5' />
      <Divider space='5' />
      <WhyChooseUs content={content['leistungen']} />
      {/* <Divider space='5' />
      <Divider space='5' />
      <FeatureTwo content={content['coronavirus']} reverse />
      <Divider space='5' />
      <Divider space='5' />
      {covidData && vaccineData && date &&
        <>
          <Stats content={content['stats']} covid={covidData} vaccine={vaccineData} date={date} />
          <Divider space='4' />
        </>
      } */}
      {/* <Strategies content={content['strategies']} />
      <Divider space='4' />
      <Download content={content['download']} />
      <Divider space='5' /> */}
      {/* <Divider space='5' />
      <Testimonials content={content['testimonials']} /> */}
      <Divider space='5' />
      <Divider space='5' />
      {/* <Container sx={styles.getStartedContainer}> */}
        <GetStarted content={content['get-started']} />
      {/* </Container> */}
      <Divider space='5' />
      {/* <Divider space='5' />
      <Blog content={content['latest-blogs']} />
      <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/index", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
